import React from 'react';
import styled from 'styled-components';
import { LoadingProps } from '../types';
import darkDotStraightSwing from '@loadings/loadings/dotStraightSwing/dark.html';
import lightDotStraightSwing from '@loadings/loadings/dotStraightSwing/light.html';
import '@loadings/loadings/dotStraightSwing/styles.scss';
import LoadingSymbol from './LoadingSymbol';

const DotStraightSwing = (props: LoadingProps) => (
   <LoadingSymbol {...props} dark={darkDotStraightSwing} light={lightDotStraightSwing} />
);

export default styled(DotStraightSwing)`
   transform: scale(${(props) => (props.size === 'small' ? '0.8' : '1')});
`;
