import React from 'react';
import styled from 'styled-components';
import { LoadingProps } from '../types';
import darkOval from '@loadings/loadings/oval/dark.html';
import lightOval from '@loadings/loadings/oval/light.html';
import LoadingSymbol from './LoadingSymbol';

const Oval = (props: LoadingProps) => <LoadingSymbol {...props} dark={darkOval} light={lightOval} />;

export default styled(Oval)``;
