import { GenericObject } from '@avtkit/types/commons';
import { MutableRefObject } from 'react';

export const limitFloatRange = (num: number, limit: number = 1) => Math.round(num * 10 ** limit) / 10 ** limit;

type RefType<T> = ((instance: T | null) => void) | MutableRefObject<T | null> | null;

export const assignRef = <T>(ref: T | null, to: RefType<T | null>) => {
   if (typeof to === 'function') {
      to(ref);
   } else if (to) {
      // eslint-disable-next-line no-param-reassign
      (to as MutableRefObject<T | null>).current = ref;
   }
};

export const getCurrentTimeUTC = () => {
   const tmLoc = new Date();
   return tmLoc.getTime() + tmLoc.getTimezoneOffset() * 60000;
};

export const calRelativeTime = (value: string, locale = 'en', formatOptions: Intl.RelativeTimeFormatOptions = {}) => {
   const currentTime = Date.now() / 1000;

   const [date, time] = value.trim().split(' ');
   const utcDateParams = [
      ...date.split('-').map((v, index) => parseInt(v, 10) + (index === 1 ? -1 : 0)),
      ...time.split(':').map((v) => parseInt(v, 10)),
   ];

   // @ts-ignore
   const targetTime = Date.UTC(...utcDateParams) / 1000;

   const rtf = new Intl.RelativeTimeFormat(locale, { numeric: 'auto', ...formatOptions });
   const timeDifference = Math.round(targetTime - currentTime);

   switch (true) {
      case Math.abs(timeDifference) < 60:
         return rtf.format(Math.round(timeDifference), 'seconds');
      case Math.abs(timeDifference) > 60 && Math.abs(timeDifference) < 3600:
         return rtf.format(Math.round(timeDifference / 60), 'minute');
      case Math.abs(timeDifference) > 3600 && Math.abs(timeDifference) < 86400:
         return rtf.format(Math.round(timeDifference / 3600), 'hour');
      case Math.abs(timeDifference) > 86400 && Math.abs(timeDifference) < 2592000:
         return rtf.format(Math.round(timeDifference / 86400), 'day');
      default:
         return rtf.format(Math.round(timeDifference / 2592000), 'month');
   }
};

export const formatDateAndTime = (dateStr: string) => {
   const reg = /(\d{1,4})-(\d{1,2})-(\d{1,2})\s(\d{1,2}):(\d{1,2}):(\d{1,2})/g;

   if (!reg.test(dateStr)) {
      return dateStr;
   }

   return new Date(dateStr).toLocaleDateString(navigator.language, { dateStyle: 'medium' });
};

export const fitToBox = (
   type: 'cover' | 'contain',
   boxW: number,
   boxH: number,
   width: number,
   height: number,
   floatRage: number = 0
) => {
   const wr = boxW / width;
   const hr = boxH / height;
   const ratio = type === 'cover' ? Math.max(wr, hr) : Math.min(wr, hr);

   if (floatRage !== undefined) {
      return {
         width: limitFloatRange(width * ratio, floatRage),
         height: limitFloatRange(height * ratio, floatRage),
      };
   }

   return {
      width: width * ratio,
      height: height * ratio,
   };
};

export const domRectToObject = ({ left, top, right, bottom, width, height, x, y }: DOMRect) => ({
   left,
   right,
   bottom,
   width,
   height,
   x,
   y,
   top,
});

export const htmlDecode = (input: string) => {
   const doc = new DOMParser().parseFromString(input, 'text/html');
   return doc.documentElement.textContent!;
};

export const getFormData = (object: GenericObject) => {
   const formData = new FormData();

   Object.entries(object).forEach(([key, value]) => {
      if (typeof value !== 'object') {
         formData.append(key, value);
      } else {
         formData.append(key, JSON.stringify(value));
      }
   });

   return formData;
};

export const formDataToObject = (data: FormData) => {
   const obj: GenericObject = {};
   data.forEach((value, key) => {
      obj[key] = value;
   });

   return obj;
};
