import React from 'react';
import styled from 'styled-components';
import { LoadingProps } from '../types';
import darkSpinningCircles from '@loadings/loadings/spinningCircles/dark.html';
import lightSpinningCircles from '@loadings/loadings/spinningCircles/light.html';
import LoadingSymbol from './LoadingSymbol';

const SpinningCircles = (props: LoadingProps) => (
   <LoadingSymbol {...props} dark={darkSpinningCircles} light={lightSpinningCircles} />
);

export default styled(SpinningCircles)`
   transform: scale(${(props) => (props.size === 'small' ? '0.7' : '1')});
`;
