export { getUserPanelData } from './userPanel';
export { getMySubscriptions } from './mySubscriptions';
export { getSingleSubscription, updateAutoRenewal, updateBillingInfo } from './singleSubscription';
export { getChangelog } from './changelog';
export { sendSupportFormData } from './supportForm';
export { sendLoginFormData } from './loginForm';
export { sendRegisterFormData } from './registerForm';
export { sendForgotPasswordFormData } from './forgotPasswordForm';
export { sendResetPasswordFormData } from './resetPasswordForm';
export { loginByGoogle } from './loginByGoogle';
export { updateUserProfile, changePassword } from './userProfile';

export const BASE_PATH = 'https://api.my.depicter.com/';
