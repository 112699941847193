import React from 'react';
import styled, { css } from 'styled-components';
import { LoadingProps } from '../types';
import darkDotFlashing from '@loadings/loadings/dotFlashing/dark.html';
import lightDotFlashing from '@loadings/loadings/dotFlashing/light.html';
import '@loadings/loadings/dotFlashing/styles.scss';
import LoadingSymbol from './LoadingSymbol';

const DotSpinner = (props: LoadingProps) => (
   <LoadingSymbol {...props} dark={darkDotFlashing} light={lightDotFlashing} />
);

export default styled(DotSpinner)`
   ${(props) =>
      props.size === 'small'
         ? css`
              span {
                 width: 8px;
                 height: 8px;
                 margin-right: 6px;
              }
           `
         : css`
              span {
                 width: 12px;
                 height: 12px;
                 margin-right: 9px;
              }
           `}
`;
