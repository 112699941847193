import React from 'react';
import styled from 'styled-components';
import { LoadingProps } from '../types';
import darkHearts from '@loadings/loadings/hearts/dark.html';
import lightHearts from '@loadings/loadings/hearts/light.html';
import LoadingSymbol from './LoadingSymbol';

const Hearts = (props: LoadingProps) => <LoadingSymbol {...props} dark={darkHearts} light={lightHearts} />;

export default styled(Hearts)`
   transform: scale(${(props) => (props.size === 'small' ? '0.4' : '1')});
`;
