/* eslint-disable react/no-danger */
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { LoadingSymbolProps } from '../types';
import '@loadings/styles/common.scss';

const LoadingSymbol = ({ className, dark, light, theme }: LoadingSymbolProps) => {
   const globalTheme = useContext(ThemeContext);

   return (
      <div
         className={className}
         dangerouslySetInnerHTML={{ __html: (theme || globalTheme.colors) === 'dark' ? light : dark }}
      />
   );
};

export default styled(LoadingSymbol)`
   display: inline-flex;
   align-items: center;
   justify-content: center;
`;
