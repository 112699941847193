import React from 'react';
import styled from 'styled-components';
import { LoadingProps } from '../types';
import darkBars from '@loadings/loadings/bars/dark.html';
import lightBars from '@loadings/loadings/bars/light.html';
import LoadingSymbol from './LoadingSymbol';

const Bars = (props: LoadingProps) => <LoadingSymbol {...props} dark={darkBars} light={lightBars} />;

export default styled(Bars)`
   transform: scale(${(props) => (props.size === 'small' ? '0.3' : '1')});
`;
