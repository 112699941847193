import React from 'react';
import styled from 'styled-components';
import { LoadingProps } from '../types';
import darkThreeDots from '@loadings/loadings/threeDots/dark.html';
import lightThreeDots from '@loadings/loadings/threeDots/light.html';
import LoadingSymbol from './LoadingSymbol';

const ThreeDots = (props: LoadingProps) => <LoadingSymbol {...props} dark={darkThreeDots} light={lightThreeDots} />;

export default styled(ThreeDots)`
   transform: scale(${(props) => (props.size === 'small' ? '0.4' : '1')});
`;
