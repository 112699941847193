import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { WithChildren } from '@avtkit/types/commons';
import { UserPanelData } from '../API/types';
import {
   createRemoteDataContext,
   createRemoteDataProvider,
   useRemoteDataRequest,
} from '@avtkit/helpers/RemoteDataContext';
import { AxiosRequestConfig } from 'axios';
import { axios, api } from '../API';

const initialUserPanelData: UserPanelData = {
   user: {
      firstName: '',
      lastName: '',
      email: '',
   },
   downloadInfo: {
      version: '',
      url: '',
   },
};

export const UserPanelDataContext = createRemoteDataContext<UserPanelData>();
const DataProvider = createRemoteDataProvider<UserPanelData>(UserPanelDataContext);

export const UserPanelDataProvider: React.FC<WithChildren> = (props) => (
   <DataProvider initialState={initialUserPanelData} {...props} />
);

export const useUserPanelData = () => {
   const requestConfig: AxiosRequestConfig = useMemo(() => api.getUserPanelData(), []);
   const request = useRemoteDataRequest<UserPanelData>(UserPanelDataContext, axios);
   const requestHandler = useCallback(() => request(requestConfig), [requestConfig, request]);

   return { ...useContext(UserPanelDataContext), request: requestHandler };
};
export const UserPanelRemoteDataRequest = () => {
   const { request } = useUserPanelData();
   useEffect(() => request(), [request]);

   return null;
};
