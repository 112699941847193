import { getFormData } from '@avtkit/utils/functions';
import { AxiosRequestConfig } from 'axios';
import { SingleSubscriptionRequestParams } from '../types';

export const getSingleSubscription = (params: SingleSubscriptionRequestParams): AxiosRequestConfig => ({
   url: `/mx/v1/subscription/get/${params.id}`,
   method: 'get',
});

export const updateAutoRenewal = (params: { id: string; enable: boolean }): AxiosRequestConfig => ({
   url: `/mx/v1/subscription/renewal/updated`,
   method: 'POST',
   data: getFormData(params),
});

export const updateBillingInfo = (data: FormData): AxiosRequestConfig => ({
   url: `/mx/v1/subscription/billing/update`,
   method: 'POST',
   data,
});
