import React from 'react';
import styled from 'styled-components';
import { LoadingProps } from '../types';
import darkDotReplacing from '@loadings/loadings/dotReplacing/dark.html';
import lightDotReplacing from '@loadings/loadings/dotReplacing/light.html';
import '@loadings/loadings/dotReplacing/styles.scss';
import LoadingSymbol from './LoadingSymbol';

const DotReplacing = (props: LoadingProps) => (
   <LoadingSymbol {...props} dark={darkDotReplacing} light={lightDotReplacing} />
);

export default styled(DotReplacing)`
   transform: scale(${(props) => (props.size === 'small' ? '0.8' : '1')});
`;
