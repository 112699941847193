import React from 'react';
import styled from 'styled-components';
import { LoadingProps } from '../types';
import darkRings from '@loadings/loadings/rings/dark.html';
import lightRings from '@loadings/loadings/rings/light.html';
import LoadingSymbol from './LoadingSymbol';

const Rings = (props: LoadingProps) => <LoadingSymbol {...props} dark={darkRings} light={lightRings} />;

export default styled(Rings)``;
