import React from 'react';
import styled, { css } from 'styled-components';
import { withTheme, WithThemeProps } from '@avtkit/helpers/withTheme';
import { Stylable } from '@avtkit/types/commons';
import StyledButton from '@avtkit/controls/Button/components/StyledButton';
import { ButtonProps } from '@avtkit/controls/Button/types';
import * as scheme from './theme';

export type StyledLinkProps = ButtonProps & WithThemeProps & Stylable;

const StyledLink = (props: StyledLinkProps) => <StyledButton {...props} />;

const theme = withTheme<StyledLinkProps>(scheme);

export default styled(StyledLink)`
   text-decoration: none;
   transition: color 150ms ease-out;

   ${theme.colors(
      (c: scheme.Colors) => css`
         color: ${c.color};

         &:hover {
            color: ${c.hover};
            ${c.hoverUnderline ? `text-decoration: underline;` : ''}
         }
      `
   )}

   svg {
      fill: currentColor;
      margin-right: 4px;
   }

   ${theme.sizes(
      (s: scheme.Sizes) => css`
         /* font-size: ${s.default.size}; */
         font-weight: ${s.default.fontWeight};
      `
   )}
`;
