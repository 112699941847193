import { ThemeScheme } from '@avtkit/helpers/withTheme';

const defaultColors = {
   color: '#4980F5',
   hover: '#FFFFFF',
   hoverUnderline: false,
};

export type Colors = typeof defaultColors;
export const colors: ThemeScheme<Colors> = {
   dark: defaultColors,
   light: {
      color: '#2F80ED',
      hover: '#2F80ED',
      hoverUnderline: true,
   },
   // ... define more themes here
};

/* ------------------------------------------------------------------------------ */
const defaultSizing = {
   route: {
      size: '16px',
      fontWeight: '600',
   },
   default: {
      size: '12px',
      fontWeight: '600',
   },
};

export type Sizes = typeof defaultSizing;
export const sizes: ThemeScheme<Sizes> = {
   default: defaultSizing,
   // ... define more themes here
};
