import React from 'react';
import styled from 'styled-components';
import { LoadingProps } from '../types';
import darkPuff from '@loadings/loadings/puff/dark.html';
import lightPuff from '@loadings/loadings/puff/light.html';
import LoadingSymbol from './LoadingSymbol';

const Puff = (props: LoadingProps) => <LoadingSymbol {...props} dark={darkPuff} light={lightPuff} />;

export default styled(Puff)``;
