import { AxiosRequestConfig } from 'axios';

export const updateUserProfile = (params: FormData): AxiosRequestConfig => ({
   url: `/mx/v1/user/profile/update`,
   method: 'POST',
   data: params,
});

export const changePassword = (params: FormData): AxiosRequestConfig => ({
   url: `/mx/v1/user/password/change`,
   method: 'POST',
   data: params,
});
