import React, { useMemo, useState } from 'react';
import { RemoteDataProviderProps, RemoteDataType } from './types';

export const createRemoteDataContext = <T,>() =>
   React.createContext<RemoteDataType<T>>({
      state: {} as T,
      setState: () => {},
      status: 'none',
      setStatus: () => {},
   });

export const createRemoteDataProvider =
   <T,>(context: React.Context<RemoteDataType<T>>): React.FC<RemoteDataProviderProps> =>
   ({ children, initialState, initialStatus = 'none' }) => {
      const [state, setState] = useState<T>(initialState);
      const [status, setStatus] = useState<RemoteDataType['status']>(initialStatus);

      const contextValue = useMemo(
         () => ({
            state,
            status,
            setState,
            setStatus,
         }),
         [state, status]
      );

      return <context.Provider value={contextValue}>{children}</context.Provider>;
   };
