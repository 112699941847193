import Axios from 'axios';
// import * as mock from './mock';
import * as remote from './remote';

export const API_PATH = remote.BASE_PATH;

export const axios = Axios.create({
   baseURL: API_PATH,
   timeout: 15000,
});

export const api = remote;
