import { MembersAreaEnvVars } from './browser.envType';

const defaultEnv: MembersAreaEnvVars = {
   rootElement: '#depicter-templates-widget',
   membersAreaAPI: 'http://localhost:9003/mock-members',
   csrfToken: '',
   googleClientId: '968189795374-85rjubt6q6j3jeiis22oiq4bacohml7g.apps.googleusercontent.com',
   activation: {
      domain: 'averta.net',
      url: 'https://averta.net',
   },
};

export default {
   ...defaultEnv,
   ...(window as any)?.depicterMembersAreaEnv,
} as MembersAreaEnvVars;
