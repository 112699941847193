import React from 'react';
import styled from 'styled-components';
import { LoadingProps } from '../types';
import darkAudio from '@loadings/loadings/audio/dark.html';
import lightAudio from '@loadings/loadings/audio/light.html';
import LoadingSymbol from './LoadingSymbol';

const Audio = (props: LoadingProps) => <LoadingSymbol {...props} dark={darkAudio} light={lightAudio} />;

export default styled(Audio)`
   transform: scale(${(props) => (props.size === 'small' ? '0.6' : '1')});
`;
