import React from 'react';
import styled from 'styled-components';
import { LoadingProps } from '../types';
import darkDotSwing from '@loadings/loadings/dotSwing/dark.html';
import lightDotSwing from '@loadings/loadings/dotSwing/light.html';
import '@loadings/loadings/dotSwing/styles.scss';
import LoadingSymbol from './LoadingSymbol';

const DotSwing = (props: LoadingProps) => <LoadingSymbol {...props} dark={darkDotSwing} light={lightDotSwing} />;

export default styled(DotSwing)`
   transform: scale(${(props) => (props.size === 'small' ? '0.8' : '1')});
`;
