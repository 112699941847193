import React from 'react';
import styled from 'styled-components';
import { LoadingProps } from '../types';
import darkGrid from '@loadings/loadings/grid/dark.html';
import lightGrid from '@loadings/loadings/grid/light.html';
import LoadingSymbol from './LoadingSymbol';

const Grid = (props: LoadingProps) => <LoadingSymbol {...props} dark={darkGrid} light={lightGrid} />;

export default styled(Grid)`
   transform: scale(${(props) => (props.size === 'small' ? '0.4' : '1')});
`;
