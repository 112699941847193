import { useDynamicTooltip } from '@avtkit/hooks/useDynamicTooltip';
import React, { forwardRef } from 'react';
import { DotSpinner } from '@avtkit/controls/Loading';
import { ButtonProps } from '../types';

/* ------------------------------------------------------------------------------ */
// Button wrapper components, we use anchor element for creating a link button
type NormalWrapper = {
   className?: string;
   children?: React.ReactNode;
};

type LinkProps = NormalWrapper & {
   href?: string;
   target?: string;
};

const LinkWrapper = forwardRef<HTMLElement, LinkProps>((props, ref) => (
   // eslint-disable-next-line jsx-a11y/anchor-has-content
   <a ref={ref as React.ForwardedRef<HTMLAnchorElement>} {...props} />
));

const ButtonWrapper = forwardRef<HTMLElement, NormalWrapper>((props, ref) => (
   <button type="button" ref={ref as React.ForwardedRef<HTMLButtonElement>} {...props} />
));

/* ------------------------------------------------------------------------------ */
const ButtonLogic = forwardRef<HTMLElement, ButtonProps>((props, ref) => {
   let content: React.ReactNode;
   const {
      disabled,
      value,
      icon,
      children,
      href,
      target,
      className,
      iconAlign,
      onMouseOut,
      onMouseOver,
      onMouseEnter,
      onMouseLeave,
      onClick,
      tooltip,
      loading,
      elementType = 'text',
   } = props;

   // button content and icon alignment
   if (icon) {
      content = (
         <>
            {iconAlign === 'left' && icon}
            {children && <span>{children}</span>}
            {iconAlign === 'right' && icon}
         </>
      );
   } else {
      content = (children && <span>{children}</span>) || '';
   }

   // events
   const events = {
      onClick(event: React.MouseEvent) {
         if (disabled || loading) {
            event.preventDefault();
            return;
         }

         if (onClick) {
            onClick(event, value);
         }
      },
      ...(onMouseOut && { onMouseOut }),
      ...(onMouseOver && { onMouseOver }),
      ...(onMouseEnter && { onMouseEnter }),
      ...(onMouseLeave && { onMouseLeave }),
   };

   // Button wrapper element
   const Wrapper = href ? LinkWrapper : ButtonWrapper;

   // Anchor wrapper params
   let wrapperParams = {};
   if (href) {
      wrapperParams = { href, target };
   } else {
      wrapperParams = {
         type: elementType,
      };
   }

   if (tooltip && !(disabled || loading)) {
      const { place = 'top', id: group } = tooltip.options || {};
      wrapperParams = {
         ...wrapperParams,
         key: tooltip.content,
         'data-tip': tooltip.content,
         'data-place': place,
         'data-for': group,
      };
   }

   useDynamicTooltip([disabled, loading]);

   return (
      <Wrapper className={className} {...events} {...wrapperParams} ref={ref}>
         {loading ? <DotSpinner size="small" /> : null}
         {content}
      </Wrapper>
   );
});

ButtonLogic.defaultProps = {
   iconAlign: 'left',
} as Partial<ButtonProps>;

export default ButtonLogic;
