import React from 'react';
import styled, { css } from 'styled-components';
import { withTheme, WithThemeProps } from '@avtkit/helpers/withTheme';
import { Link, LinkProps } from 'react-router-dom';
import { Stylable } from '@avtkit/types/commons';
import * as scheme from './theme';
import Back from '@avtkit/controls/Icon/shapes/BackArrow.svgr';

export type RouterLinkProps = LinkProps &
   WithThemeProps &
   Stylable & {
      backIcon?: boolean;
   };

const StyledLink = ({ backIcon, children, ...props }: RouterLinkProps) => (
   <Link {...props}>
      {backIcon && <Back />}
      {children}
   </Link>
);

const theme = withTheme<RouterLinkProps>(scheme);

export default styled(StyledLink)`
   text-decoration: none;
   transition: color 150ms ease-out;

   svg {
      margin-right: 5px;
   }
   ${theme.colors(
      (c: scheme.Colors) => css`
         color: ${c.color};

         svg {
            fill: ${c.color};
         }

         &:hover {
            color: ${c.hover};
         }
      `
   )}

   ${theme.sizes(
      (s: scheme.Sizes) => css`
         font-size: ${s.route.size};
         font-weight: ${s.route.fontWeight};
      `
   )}
`;
